.new-symbol {
    display: inline-block;
    background-color: #2ecc71;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: bold;
    margin-left: 5px;
}

.old-symbol {
    display: inline-block;
    background-color: #781212;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: bold;
    margin-left: 5px;
}

.shiny-box, .coming-soon {
    display: inline-block;
    padding: 2px 8px;
    background: linear-gradient(45deg, #f2c94c, #f39c12, #e67e22, #d35400);
    border-radius: 12px;
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 5px;
    animation: shine 1.5s infinite alternate;
}